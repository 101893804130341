import { qsa } from "/js/utils/dom"

export const initFAQ = () => {
  const faqs = qsa(".faq__details")
  setDetailsHeights(faqs)

  function clickHandler(faq) {
    faq.addEventListener("click", (e) => {
      faqs.map((otherFaq) => {
        if (
          e.target.closest("details") != otherFaq &&
          otherFaq.hasAttribute("open")
        ) {
          otherFaq.removeAttribute("open")
        }
      })
    })
  }

  faqs.forEach((faq) => {
    clickHandler(faq)
  })
}

export function setDetailsHeights(detailElements) {
  const setHeights = (details) => {
    const initiallyOpen = details.open
    details.dataset.width = details.offsetWidth
    details.open = true
    details.style.setProperty("--expanded", `${details.offsetHeight}px`)
    details.open = false
    details.style.setProperty("--collapsed", `${details.offsetHeight}px`)
    details.open = initiallyOpen
  }
  const RO = new ResizeObserver((entries) => {
    return entries.forEach((entry) => {
      const details = entry.target
      const width = parseInt(details.dataset.width, 10)
      if (width !== details.offsetWidth) {
        details.removeAttribute("style")
        setHeights(details)
      }
    })
  })
  detailElements.forEach((details) => {
    RO.observe(details)
  })
}
