// import { ClipboardLink, FacebookLink } from "./someLinks"

export const MarkerDetails = (marker) => {
  return (
    <div className="marker-details" onClick={marker.close}>
      <button className="button button--close">
        <svg className="icon">
          <use xlinkHref="#icon-close" />
        </svg>
      </button>
      {marker.image && (
        <div className="marker-details__image">
          <img src={marker.image} alt={marker.title} />
        </div>
      )}
      <div className="marker-details__details">
        {marker.title && <h3>{marker.title}</h3>}
        {marker.description && <p>{marker.description}</p>}
        {marker.children}
      </div>
      {(marker.location_title || marker.location_info) && (
        <div className="marker-details__location">
          <h4>{marker.location_title}</h4>
          <p>{marker.location_info}</p>
        </div>
      )}
      {/* <div className="marker-details__some">
        <FacebookLink url={marker.url} />
        <ClipboardLink url={marker.url} />
      </div> */}
    </div>
  )
}
