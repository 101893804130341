import "mapbox-gl/dist/mapbox-gl.css"

import React, { useState, Fragment, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import ReactMapGL, { FlyToInterpolator, Marker } from "react-map-gl"
import useSupercluster from "use-supercluster"
import { MarkerDetails } from "./markerDetails"
import { qs } from "../utils/dom"
import { useBreakpoints } from "../utils/hooks"
import { FilterButton } from "./filterButton"
import { CardList } from "./markerCard"

export const MAP_STYLE =
  "mapbox://styles/feinheit/ckrbr16zq0t3s18q9ccyqke0y/draft"
const FILTERS = [
  { id: "all", name: "Alle" },
  { id: "productlocation", name: "Produkte" },
  // { id: "project_location", name: "Projekte" },
  { id: "activitylocation", name: "Aktivitäten" },
]

const CARDS_PER_PAGE = 6

const fetchData = async (url) => {
  let response = await fetch(url)
  if (response.ok) {
    return response.json()
  }
}

const Map = ({ token, endpoint }) => {
  const getViewport = () => {
    return {
      zoom: useBreakpoints("lg") ? 7.5 : useBreakpoints("md") ? 6.3 : 5.5,
      latitude: 46.8,
      longitude: 8.2,
    }
  }

  const [viewport, setViewport] = useState(getViewport())
  const [markers, setMarkers] = useState([])
  const [cardData, setCardData] = useState([])
  const [activeFilter, setActiveFilter] = useState("all")
  const [activeMarker, setActiveMarker] = useState("")
  const [slices, setSlices] = useState(1)

  const shownCards = cardData.slice(0, CARDS_PER_PAGE * slices)

  const mapRef = useRef()

  // const resizeEffects = () => {
  //   setViewport(getViewport())
  // }

  useEffect(() => {
    async function fetchMapData() {
      let data = await fetchData(`${endpoint}?markers`)
      setMarkers(data)
    }
    fetchMapData()

    async function fetchCardData() {
      let data = await fetchData(`${endpoint}?slider_data`)
      setCardData(
        data
          .map((n) => {
            return [Math.random(), n]
          })
          .sort()
          .map((n) => {
            return n[1]
          }),
      )
    }
    fetchCardData()
  }, [])

  // useEffect(() => {
  //   window.addEventListener("resize", resizeEffects)
  //   return () => window.removeEventListener("resize", resizeEffects)
  // }, [])

  //  generate Clusters
  const points = markers
    ? markers
        .filter((m) => activeFilter === "all" || m.type == activeFilter)
        .map((m) => ({
          type: "Feature",
          properties: {
            cluster: false,
            markerID: m.id,
            category: m.type,
            icon: m.icon,
          },
          geometry: {
            type: "Point",
            coordinates: [
              Number(m.location.split(",")[1]),
              Number(m.location.split(",")[0]),
            ],
          },
        }))
    : []
  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : []
  const { clusters } = useSupercluster({
    points,
    zoom: viewport.zoom,
    bounds,
    options: {
      radius: 20,
      maxZoom: 14,
    },
  })

  // map animations
  const deCluster = (cluster) => {
    const [longitude, latitude] = cluster.geometry.coordinates
    const newZoom = viewport.zoom + 2
    setViewport({
      zoom: newZoom > 10 ? newZoom : 10,
      latitude,
      longitude,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }

  const markerZoom = (latitude, longitude) => {
    setViewport({
      zoom: viewport.zoom < 10 ? 10 : viewport.zoom,
      latitude,
      longitude,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }

  const zoomControl = (zoomChange) => {
    const { latitude, longitude, zoom } = viewport
    setViewport({
      zoom: zoom + zoomChange,
      latitude,
      longitude,
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }

  const calculateIconSize = () => {
    const rem = (2.75 * viewport.zoom) / 14
    return `${rem}rem`
  }

  return (
    <>
      <div className="map__container">
        <div className="map__map">
          <ReactMapGL
            {...viewport}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken={token}
            mapStyle={MAP_STYLE}
            minZoom={5.5}
            maxZoom={16}
            width="100%"
            height="100%"
            scrollZoom={false}
            ref={mapRef}
          >
            {clusters.length > 0 &&
              clusters.map((c) => {
                const [longitude, latitude] = c.geometry.coordinates
                const { cluster: isCluster } = c.properties

                return isCluster ? (
                  <Marker
                    className="map-cluster"
                    key={c.id}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={() => {
                      deCluster(c)
                    }}
                  >
                    <svg className="icon">
                      <use href="#icon-cluster" />
                    </svg>
                  </Marker>
                ) : (
                  <Marker
                    className={`map-marker ${c.properties.icon}`}
                    key={c.properties.markerID}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={() => {
                      setActiveMarker(
                        markers.find((m) => m.id == c.properties.markerID),
                      )
                      markerZoom(latitude, longitude)
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ "--icon-size": calculateIconSize() }}
                    >
                      <use href={`#icon-marker-${c.properties.icon}`} />
                    </svg>
                  </Marker>
                )
              })}
            {activeMarker && (
              <div className="map__map-markers__active__container">
                <div className="container mx-auto">
                  {activeMarker === "info" ? (
                    <MarkerDetails
                      close={() => {
                        setActiveMarker("")
                      }}
                    >
                      <div className="marker-details__info">
                        Heute schwimmen noch keine Lachse in Schweizer
                        Gewässern. Trotzdem: Er macht schon mächtig Radau!
                        Entdecke alle Lachs-Projekte und engagiere dich in
                        deiner Region.
                      </div>
                    </MarkerDetails>
                  ) : (
                    <MarkerDetails
                      {...activeMarker}
                      close={() => {
                        setActiveMarker("")
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="map__map-markers__overlay">
              <div className="container mx-auto">
                <div className="flex-grid map__map-markers__filter__container">
                  {FILTERS.map((f) => (
                    <FilterButton
                      key={f.id}
                      {...f}
                      onClick={() => {
                        setActiveFilter(f.id)
                      }}
                      active={f.id === activeFilter}
                      classes="button--lachs"
                    />
                  ))}
                </div>
                <div className="flex-grid map__map-markers__controls">
                  <button
                    className="button button--lachs controls--center"
                    onClick={() => {
                      const { zoom, latitude, longitude } = getViewport()
                      setViewport({
                        zoom,
                        latitude,
                        longitude,
                        transitionDuration: 500,
                        transitionInterpolator: new FlyToInterpolator(),
                      })
                    }}
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-undo" />
                    </svg>
                  </button>
                  <button
                    className="button button--lachs controls--zoom-in"
                    onClick={() => {
                      zoomControl(2)
                    }}
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-zoom-in" />
                    </svg>
                  </button>
                  <button
                    className="button button--lachs controls--zoom-out"
                    onClick={() => {
                      zoomControl(-2)
                    }}
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-zoom-out" />
                    </svg>
                  </button>
                  <button
                    className="button button--lachs controls--info"
                    onClick={() => {
                      setActiveMarker("info")
                    }}
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-map-info" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </ReactMapGL>
        </div>
      </div>
      {cardData.length > 0 && (
        <div className="container mx-auto map__map-markers__card-list__container">
          <CardList data={shownCards} />
          {cardData.length > shownCards.length ? (
            <div className="show-more">
              <button
                type="button"
                className="button button--more"
                onClick={() => {
                  setSlices(slices + 1)
                }}
              >
                Mehr anzeigen
              </button>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export const initMap = () => {
  const mapElement = qs("#map")

  if (mapElement) {
    ReactDOM.render(
      <Map
        token={mapElement.dataset.token}
        endpoint={mapElement.dataset.endpoint}
      />,
      mapElement,
    )
  }
}
