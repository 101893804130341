import { useState, useRef, useEffect } from "react"
import { SwipeDetector } from "swipedetector"
import useThrottledCallback from "beautiful-react-hooks/useThrottledCallback"
import useGlobalEvent from "beautiful-react-hooks/useGlobalEvent"
import { qs } from "../utils/dom"

let last = 0

export const Carousel = ({ slides, SlideComponent }) => {
  const [containerHeight, setContainerHeight] = useState(0)
  const [current, setCurrent] = useState(0)

  const slidesContainerRef = useRef()

  const onResize = useGlobalEvent("resize")
  const onResizeHandler = useThrottledCallback(() => {
    const slide = qs(".slide", slidesContainerRef.current)
    setContainerHeight(slide.offsetHeight)
  })
  onResize(onResizeHandler)

  useEffect(() => {
    const events = new SwipeDetector(slidesContainerRef.current).emitter
    events.on("left", next)
    events.on("right", previous)
  }, [current])

  useEffect(() => {
    setTimeout(() => {
      const slide = qs(".slide", slidesContainerRef.current)
      setContainerHeight(slide.offsetHeight)
    }, 250)
  }, [])

  const next = () => {
    let nextSlide = current + 1
    last = current
    if (nextSlide > slides.length - 1) nextSlide = 0
    setCurrent(nextSlide)
  }

  const previous = () => {
    let nextSlide = current - 1
    last = current
    if (nextSlide < 0) nextSlide = slides.length - 1
    setCurrent(nextSlide)
  }

  return (
    <div className="carousel">
      <div
        className={`carousel__slides-container${
          last > current ? " from-left" : " from-right"
        }`}
        ref={slidesContainerRef}
        style={{ minHeight: containerHeight }}
      >
        <button className="carousel__control--previous" onClick={previous}>
          <svg className="icon">
            <use xlinkHref="#icon-arrow-back" />
          </svg>
        </button>
        <SlideComponent data={slides[current]} key={current} />
        <button className="carousel__control--next" onClick={next}>
          <svg className="icon">
            <use xlinkHref="#icon-arrow-forward" />
          </svg>
        </button>
      </div>
      <Thumbs count={slides.length} current={current} setCurrent={setCurrent} />
    </div>
  )
}

const Thumbs = ({ count, current, setCurrent }) => {
  const thumbsGenerator = (i = 0, arr = []) => {
    if (i === count) return arr
    arr.push(
      <Thumb key={i} self={i} current={current} setCurrent={setCurrent} />,
    )
    i++
    return thumbsGenerator(i, arr)
  }
  return <div className="flex-grid carousel__thumbs">{thumbsGenerator()}</div>
}

const Thumb = ({ self, current, setCurrent }) => {
  return (
    <div
      className={`carousel__thumb${self === current ? " active" : ""}`}
      onClick={() => {
        last = current
        setCurrent(self)
      }}
    />
  )
}
