import { useState } from "react"
import { render } from "react-dom"
import { qs } from "../utils/dom"
import { FilterButton } from "./filterButton"
import { pillFilter } from "../utils/hooks"

const OPERATION_SLICE_SIZE = 12

export const initOperationsApp = () => {
  const operationsElement = qs("#operations-app")
  const data = qs("#operations-data")

  if (operationsElement && data) {
    render(
      <OperationsApp {...JSON.parse(data.textContent)} />,
      operationsElement,
    )
  }
}

const OperationsApp = ({ groupFilter, typeFilter, operations }) => {
  const [activeGroupFilter, setActiveGroupFilter] = useState([])
  const [activeTypeFilter, setActiveTypeFilter] = useState([])
  const [slices, setSlices] = useState(1)

  const filteredOperations = operations.filter((o) => {
    return (
      (activeGroupFilter.length === 0 ||
        activeGroupFilter.every((f) => o.groups.includes(f))) &&
      (activeTypeFilter.length === 0 ||
        activeTypeFilter.every((f) => o.types.includes(f)))
    )
  })

  return (
    <div className="operations">
      <div className="operations__filter-container">
        <div className="flex-grid operations__group-filter-container">
          {groupFilter.map((f) => {
            return (
              <FilterButton
                key={f[0]}
                active={activeGroupFilter.includes(f[0])}
                name={f[1]}
                onClick={() => {
                  pillFilter(activeGroupFilter, setActiveGroupFilter, f[0])
                }}
              />
            )
          })}
        </div>
        <div className="flex-grid operations__type-filter-container">
          {typeFilter.map((f) => {
            return (
              <FilterButton
                key={f[0]}
                active={activeTypeFilter.includes(f[0])}
                name={f[1]}
                onClick={() => {
                  pillFilter(activeTypeFilter, setActiveTypeFilter, f[0])
                }}
              />
            )
          })}
        </div>
      </div>

      {filteredOperations.length === 0 ? (
        <div className="operations__operations-container">
          Keine Einträge gefunden.{" "}
          {activeTypeFilter.length > 0 || activeGroupFilter.length > 0 ? (
            <button
              className="button button--inline"
              onClick={() => {
                setActiveTypeFilter([])
                setActiveGroupFilter([])
              }}
            >
              Filter zurücksetzen
            </button>
          ) : undefined}
        </div>
      ) : (
        <div className="flex-grid column operations__operations-container">
          {filteredOperations
            .slice(0, slices * OPERATION_SLICE_SIZE)
            .map((o) => (
              <Operation key={o.id} {...o} />
            ))}
        </div>
      )}

      <div className="show-more">
        {filteredOperations.length > slices * OPERATION_SLICE_SIZE && (
          <button
            className="button"
            onClick={() => {
              setSlices(slices + 1)
            }}
          >
            Mehr anzeigen
          </button>
        )}
      </div>
    </div>
  )
}

const Operation = (o) => {
  return (
    <a className="card operation" href={o.url}>
      <div
        className="operation__image"
        style={{ backgroundImage: `url(${o.image})` }}
      />
      <div className="operation__info">
        <h3>{o.title}</h3>
        <p>{o.text}</p>
      </div>
      <div className="operation__arrow">
        <svg className="icon">
          <use xlinkHref="#icon-arrow-forward" />
        </svg>
      </div>
    </a>
  )
}
