import { createContext, useContext, useState } from "react"
import { render } from "react-dom"
import { Masonry } from "../utils/masonry"
import { qs, qsa } from "../utils/dom"
import { useBreakpoint } from "../utils/breakpoints"
import { FilterButton } from "./filterButton"

const TestimonialContext = createContext()
const useTestimonialContext = () => useContext(TestimonialContext)

const DEFAULTS = {
  paginateBy: 12,
  listBase: "",
}

export const initTestimonials = () => {
  let data
  for (let testimonialsEl of qsa("[data-testimonials]")) {
    if ((data = qs("script", testimonialsEl))) {
      console.log(data)
      render(
        <TestimonialContext.Provider
          value={{ ...DEFAULTS, ...testimonialsEl.dataset }}
        >
          <TestimonialsApp data={JSON.parse(data.textContent || {})} />
        </TestimonialContext.Provider>,
        testimonialsEl,
      )
    }
  }
}

const filterTestimonials = (data, filterWord) => {
  if (!filterWord.length) return data
  return data.filter((obj) => filterWord.includes(obj.canton))
}

const TestimonialsApp = ({ data }) => {
  const cantons = data.cantonFilterData

  const testimonials = data.testimonialData
  const { paginateBy } = useTestimonialContext()
  const [slices, setSlices] = useState(1)
  const [filterWord, setFilterWord] = useState([])

  const filteredTestimonials = filterTestimonials(testimonials, filterWord)
  const shownTestimonials = filteredTestimonials.slice(
    (slices - 1) * paginateBy,
    slices * paginateBy,
  )

  const showNext = slices * paginateBy < filteredTestimonials.length
  const showPrevious = (slices - 1) * paginateBy > 0

  const columnsM = useBreakpoint("md") && 2
  const columnsL = useBreakpoint("lg") && 3

  return (
    <>
      <div className="testimonials-filter">
        {cantons.map((canton) => (
          <FilterButton
            key={canton[0]}
            active={filterWord.indexOf(canton[0]) !== -1}
            name={canton[1]}
            onClick={() =>
              setFilterWord((array) => {
                const newArray = [...array]
                const i = newArray.indexOf(canton[0])
                if (i !== -1) {
                  newArray.splice(i, 1)
                } else {
                  newArray.push(canton[0])
                }
                return newArray
              })
            }
          />
        ))}
      </div>
      <Masonry
        columns={columnsL || columnsM || 1}
        styleClass="testimonials-grid"
        columnStyleClass="testimonials-grid__column"
      >
        {shownTestimonials.length < 1 && (
          <div className="">Keine Botschaften gefunden.</div>
        )}
        {shownTestimonials.map((testimonial) => (
          <TestimonialBox key={testimonial.id} data={testimonial} />
        ))}
      </Masonry>
      {showNext || showPrevious ? (
        <div className="testimonials-grid__show-more">
          {showPrevious ? (
            <button
              type="button"
              className="button button--more"
              onClick={() => {
                setSlices(slices - 1)
              }}
            >
              Vorherige Seite
            </button>
          ) : null}
          {showNext ? (
            <button
              type="button"
              className="button button--more"
              onClick={() => {
                setSlices(slices + 1)
              }}
            >
              Nächste Seite
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

const TestimonialBox = ({ data }) => {
  const { listBase } = useTestimonialContext()
  const sharingText = `${encodeURI(data.name)} \n`
  const sharingURL = `${listBase}${data.id}/`

  return (
    <div className="testimonials-grid__item">
      <div className="testimonials-grid__item-inner">
        <img
          className="testimonials-grid__image"
          src={data.portrait}
          alt={`Portrait of ${data.name}`}
        />
        <h3 className="testimonials-grid__name">
          <span>{data.first_name}</span>
          <br />
          <span>{data.last_name}</span>
        </h3>
        <p className="testimonials-grid__profession">{data.profession}</p>
        {data.statement ? (
          <p className="testimonials-grid__statement">«{data.statement}»</p>
        ) : (
          <img
            src="/static/assets/ichunterstützedenLachs.svg"
            className="testimonials-grid__no-statement"
          />
        )}
        <div className="testimonials-grid__separator" />
        <div className="testimonials-grid__sharing">
          <DownloadLink
            src={data.sharing_image_square}
            title={"Share on Instagram"}
          >
            <svg width="17" height="17">
              <use xlinkHref="#icon-instagram" />
            </svg>
          </DownloadLink>
          <ShareLink
            title={"Share on Facebook"}
            url={`https://www.facebook.com/sharer.php?u=${sharingURL}`}
          >
            <svg width="9" height="17">
              <use xlinkHref="#icon-facebook" />
            </svg>
          </ShareLink>
          <ShareLink
            title={"Share on Whatsapp"}
            url={`https://api.whatsapp.com/send?phone=&text=${sharingText}${sharingURL}`}
          >
            <svg width="17" height="17" transform="scale(1.1)">
              <use xlinkHref="#icon-whatsapp" />
            </svg>
          </ShareLink>
          <ShareLink
            title={"Share on Twitter"}
            url={`https://twitter.com/share?url=${sharingURL}`}
          >
            <svg width="19" height="15">
              <use xlinkHref="#icon-twitter" />
            </svg>
          </ShareLink>
          <ShareLink
            title={"Share on Telegram"}
            url={`https://t.me/share/url?url=${sharingURL}&text=${sharingText}`}
          >
            <svg width="18" height="14">
              <use xlinkHref="#icon-telegram" />
            </svg>
          </ShareLink>
          <DownloadLink
            src={data.sharing_image_landscape}
            title={"Download testimonial"}
          >
            <svg width="17" height="17">
              <use xlinkHref="#icon-download" />
            </svg>
          </DownloadLink>
        </div>
      </div>
    </div>
  )
}

const ShareLink = ({ title, url, children }) => {
  return (
    <a href={url} title={title} target="_blank" rel="noopener noreferrer">
      <span className="sr-only">{title}</span>
      {children}
    </a>
  )
}

const DownloadLink = ({ title, src, children }) => {
  return (
    <a href={src} title={title} download>
      <span className="sr-only">{title}</span>
      {children}
    </a>
  )
}
