import { render } from "react-dom"
import { qs } from "../utils/dom"
import { Carousel } from "./carousel"

export const initGalleryImagesCarousel = () => {
  const carousel = qs("#gallery-app")
  const slidesData = qs("#gallery-data")

  if (carousel) {
    render(
      <Carousel
        slides={JSON.parse(slidesData.textContent)}
        SlideComponent={TestimonialSlide}
      />,
      carousel,
    )
  }
}

const TestimonialSlide = ({ data }) => {
  return (
    <div key={data.id} className="slide testimonial-slide">
      <div className="testimonial-slide__image">
        <picture>
          <source media="(min-width: 1025px)" srcSet={data.image_large} />
          <source media="(min-width: 513px)" srcSet={data.image_default} />
          <img src={data.image_small} alt={`Portrait: ${data.name}`} />
        </picture>
      </div>
      <div className="testimonial-slide__info">
        <div className="testimonial-slide__info__text">{data.text}</div>
        <div className="testimonial-slide__info__name">{data.name}</div>
      </div>
    </div>
  )
}
