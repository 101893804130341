import { qsa } from "../utils/dom"

export const initAnimations = () => {
  const animationsElements = qsa(".intersection-animate")

  if (animationsElements.length > 0) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("animate")
        }
      })
    })

    animationsElements.forEach((el) => {
      observer.observe(el)
    })
  }
}
