// import { ClipboardLink, FacebookLink } from "./someLinks"

export const MarkerCard = ({ image, title, description, url }) => {
  const cardContent = (
    <>
      {image && (
        <div className="marker-card__image">
          <img src={image} alt={title} />
        </div>
      )}
      <div className="marker-card__info">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </>
  )

  return (
    <>
      {url ? (
        <CardLink url={url}>{cardContent}</CardLink>
      ) : (
        <CardSimple>{cardContent}</CardSimple>
      )}
    </>
  )
}

const CardLink = (props) => {
  return (
    <a
      className="card marker-card"
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  )
}
const CardSimple = (props) => {
  return <div className="card marker-card">{props.children}</div>
}

export const CardList = ({ data }) => {
  return (
    <div className="grid card-list">
      {data && data.map((m, i) => <MarkerCard key={i} {...m} />)}
    </div>
  )
}
