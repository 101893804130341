import { theme } from "../../../theme.config.js"

export const useBreakpoints = (key) => {
  const mediaQuery = window.matchMedia(`(min-width: ${theme.screens[key]})`)
  return mediaQuery.matches
}

export const pillFilter = (provider, setter, element) => {
  if (provider.includes(element))
    setter((_prev) => {
      return _prev.filter((f) => f !== element)
    })
  else
    setter((_prev) => {
      let _arr = [..._prev]
      _arr.push(element)
      return _arr
    })
}
