export const FilterButton = ({ active, name, onClick, classes }) => {
  return (
    <button
      className={`button button--filtering${active ? " active" : ""} ${
        classes ? classes : ""
      }`}
      onClick={onClick}
    >
      {name}
    </button>
  )
}
