import { render } from "react-dom"
import { useState, useRef, Fragment } from "react"
import getCookie from "../utils/getCookie"
import { qs } from "../utils/dom"

export const initNewsletterForm = () => {
  const form = qs("#newsletter-app")
  const formData = qs("#newsletter-data")
  return form && formData
    ? render(
        <FormDecorator
          endpoint={form.dataset.endpoint}
          fieldsData={JSON.parse(formData.textContent)}
          FormInstance={NewsletterForm}
        />,
        form,
      )
    : null
}

export const initMessagesForm = () => {
  const form = qs("#messages-app")
  const formData = qs("#messages-data")
  return form && formData
    ? render(
        <FormDecorator
          endpoint={form.dataset.endpoint}
          fieldsData={JSON.parse(formData.textContent)}
          FormInstance={MessagesForm}
        />,
        form,
      )
    : null
}

export const initOperationsForm = () => {
  const form = qs("#operations-form-app")
  const formData = qs("#operations-form-data")

  return form && formData
    ? render(
        <FormDecorator
          endpoint={form.dataset.endpoint}
          fieldsData={JSON.parse(formData.textContent)}
          FormInstance={OperationsForm}
        />,
        form,
      )
    : null
}

const OperationsForm = (data) => {
  console.log(data)
  return (
    <>
      <h2>{data.title}</h2>
      <input type="hidden" name="pk" value={data.id} />
      {Object.keys(data).map((key) => {
        switch (data[key].type) {
          case "text":
          case "email":
            return <CharField key={key} name={key} {...data[key]} />
          case "textarea":
            return <TextField key={key} name={key} {...data[key]} />
          case "checkbox":
            return <CheckboxField key={key} name={key} {...data[key]} />
          case "select":
            return <SelectField key={key} name={key} {...data[key]} />
          default:
            return ""
        }
      })}
      <div className="hint">* Pflichtfelder</div>
      <SubmitButton state={data.submitState} value={"Absenden"} />
    </>
  )
}

const NewsletterForm = (data) => {
  return (
    <>
      <CharField name="firstname" {...data.firstname} classes={"half"} />
      <CharField name="lastname" {...data.lastname} classes={"half"} />
      <CharField name="email" {...data.email} />
      <SelectField name="regions" {...data.regions} />
      <div className="hint">* Pflichtfelder</div>
      <SubmitButton state={data.submitState} value={"Anmelden"} />
    </>
  )
}

const MessagesForm = (data) => {
  return (
    <>
      <div className="intro-text">{data.text}</div>
      <CharField name="firstname" {...data.firstname} classes={"half"} />
      <CharField name="lastname" {...data.lastname} classes={"half"} />
      <CharField name="canton" {...data.canton} />
      <CharField name="email" {...data.email} />
      <CharField name="phone" {...data.phone} />
      <TextField name="message" {...data.message} />
      <div className="hint">* Pflichtfelder</div>
      <SubmitButton state={data.submitState} value={"Absenden"} />
    </>
  )
}

const FormDecorator = ({ endpoint, fieldsData, FormInstance }) => {
  const [fieldsAndErrorData, setFieldsAndErrorData] = useState(fieldsData)
  const [state, setState] = useState("")
  const [message, setMessage] = useState("")

  const formRef = useRef()

  const setErrors = (errors) => {
    let newFieldsAndErrorData = { ...fieldsAndErrorData }
    Object.keys(fieldsAndErrorData).forEach((k) => {
      if (!["title", "id", "submitState"].includes(k))
        newFieldsAndErrorData[k]["errors"] = errors[k] || ""
    })
    setFieldsAndErrorData(newFieldsAndErrorData)
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setState("pending")

    const response = await postData(new FormData(e.target))

    if (response.state === "ok") {
      setState("ok")
      setMessage(response.message)
    } else if (response.state === "error") {
      setState("")
      setErrors(response.errors)
      setMessage("")
    } else {
      setState("fatal")
      setMessage(response.message || "Unexpected error occurred.")
    }
  }

  const postData = async (data) => {
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: data,
    })

    return response.json()
  }

  // useEffect(() => {
  //   const firstError = formRef.current.querySelector(".form-error")
  //   if (firstError) firstError.scrollIntoView({ behavior: "smooth" })
  // }, [fieldsAndErrorData, formRef.current])

  // useEffect(() => {
  //   const successMessage = formRef.current.querySelector(".success-message")
  //   if (successMessage) successMessage.scrollIntoView({ behavior: "smooth" })
  // }, [message, formRef.current])

  return (
    <form
      onSubmit={onFormSubmit}
      ref={formRef}
      className={`flex-grid card custom-form ${
        state === "ok" ? "submitted" : ""
      }`}
    >
      {(state === "" || state === "pending") && (
        <FormInstance {...fieldsAndErrorData} submitState={state} />
      )}
      {message && <div className="success-message">{message}</div>}
    </form>
  )
}

const CharField = (data) => {
  return (
    <fieldset className={data.classes}>
      {/* <Label {...data} /> */}
      <Error {...data} />
      <input
        type="text"
        name={data.name}
        required={data.required}
        placeholder={`${data.label}${data.required ? "*" : ""}`}
      />
    </fieldset>
  )
}

const TextField = (data) => {
  return (
    <fieldset className={data.classes}>
      {/* <Label {...data} /> */}
      <Error {...data} />
      <textarea
        name={data.name}
        required={data.required}
        placeholder={`${data.label}${data.required ? "*" : ""}`}
      />
    </fieldset>
  )
}

const CheckboxField = (data) => {
  return (
    <fieldset className={data.classes}>
      <Error {...data} />
      <input
        type="checkbox"
        id={data.name}
        name={data.name}
        required={data.required}
      />
      <Label {...data} />
    </fieldset>
  )
}

const SelectField = (data) => {
  return (
    <fieldset className={data.classes}>
      <Label {...data} />
      <Error {...data} />
      <select
        name={data.name}
        onChange={
          data.setValue
            ? (e) => {
                data.setValue(e.target.value)
              }
            : () => {}
        }
        required={data.required}
      >
        {data.choices.map((c, i) => {
          return (
            <option key={i} value={c[0]}>
              {c[1]}
            </option>
          )
        })}
      </select>
    </fieldset>
  )
}

// const DateField = (data) => {
//   return (
//     <fieldset className={data.classes}>
//       <Label {...data} />
//       <Error {...data} />
//       <input type="date" name={data.name} />
//     </fieldset>
//   )
// }

const Label = (data) => {
  return (
    <>
      <label htmlFor={data.name}>
        {data.label}
        {data.required && "*"}
      </label>
    </>
  )
}

const SubmitButton = ({ state, value }) => {
  return (
    <div className="form-group__submit">
      <button
        type="submit"
        className="button button--lachs"
        value={value}
        disabled={state === "pending"}
      >
        <span>{value}</span>
        <svg className="icon">
          <use xlinkHref="#icon-arrow-forward" />
        </svg>
      </button>
    </div>
  )
}

const Error = (data) => {
  return (
    <>
      {data.errors ? (
        <div className="form-error">{data.errors.join(" ")}</div>
      ) : (
        ""
      )}
    </>
  )
}
