import { useState } from "react"
import { render } from "react-dom"
import { qsa } from "../utils/dom"

export const FacebookLink = ({ url, title }) => {
  const href = `https://www.facebook.com/sharer.php?u=${window.location.protocol}//${window.location.host}${url}`
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <svg className="icon">
        <use xlinkHref="#icon-facebook" />
      </svg>
      {title && <span>{title}</span>}
    </a>
  )
}

export const ClipboardLink = ({ url, title }) => {
  const [showDescription, setShowDescription] = useState(undefined)
  const href = `${window.location.protocol}//${window.location.host}${url}`
  return (
    <a
      className="clipboard-link"
      href={href}
      onClick={(e) => {
        e.preventDefault()
        if (navigator.clipboard) {
          navigator.clipboard?.writeText(href).then(() => {
            setShowDescription("In die Zwischenablage kopiert")
            setTimeout(() => {
              setShowDescription(undefined)
            }, 3000)
          })
        } else {
          setShowDescription("Zwischenablage nicht verfügbar")
          setTimeout(() => {
            setShowDescription(undefined)
          }, 3000)
        }
      }}
      data-description={showDescription}
    >
      <svg className="icon">
        <use xlinkHref="#icon-clipboard" />
      </svg>
      {title && <span>{title}</span>}
    </a>
  )
}

export const InstagramLink = ({ url, title }) => {
  return (
    <a href={url}>
      <svg className="icon">
        <use xlinkHref="#icon-instagram" />
      </svg>
      {title && <span>{title}</span>}
    </a>
  )
}

export const initSomeLinksInclude = () => {
  const roots = qsa(".some-links-include")

  if (roots.length > 0) {
    roots.map((r, i) => {
      switch (r.dataset.type) {
        case "facebook":
          render(<FacebookLink key={i} url={r.dataset.url} />, r)
          break
        case "instagram":
          render(<InstagramLink key={i} url={r.dataset.url} />, r)
          break
        case "clipboard":
          render(<ClipboardLink key={i} url={r.dataset.url} />, r)
          break
        default:
          break
      }
    })
  }
}
