import { useState } from "react"
import { render } from "react-dom"
import ReactMapGL, { Marker } from "react-map-gl"
import { qsa } from "../utils/dom"

import { MAP_STYLE } from "./map"

export const initInlineMap = () => {
  const mapEls = qsa(".inlinemap-app")

  if (mapEls) {
    mapEls.map((mapEl) => {
      const [lat, lng] = mapEl.dataset.marker.split(",")
      render(
        <InlineMap marker={{ lat, lng }} token={mapEl.dataset.token} />,
        mapEl,
      )
    })
  }
}

const InlineMap = ({ marker, token }) => {
  const initialViewport = {
    zoom: 9,
    latitude: Number(marker.lat),
    longitude: Number(marker.lng),
  }
  const [viewport, setViewport] = useState(initialViewport)

  return (
    <div className="inlinemap">
      <ReactMapGL
        {...viewport}
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        mapboxApiAccessToken={token}
        mapStyle={MAP_STYLE}
        minZoom={7}
        width="100%"
        height="100%"
      >
        <Marker
          className="map-marker simple-location-marker"
          latitude={Number(marker.lat)}
          longitude={Number(marker.lng)}
        >
          <svg className="icon">
            <use xlinkHref="#icon-pin" />
          </svg>
        </Marker>
      </ReactMapGL>
    </div>
  )
}
