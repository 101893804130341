import "./css/reset.css"
import "./css/admin.css"
import "./css/typography.css"
import "./css/layout.css"
import "./css/utils.css"
import "./css/grid.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/buttons.css"
import "./css/footer.css"
import "./css/aside.css"
import "./css/content.css"
import "./css/map.css"
import "./css/supporters.css"
import "./css/context.css"
import "./css/river.css"
import "./css/hero.css"
import "./css/icons.css"
import "./css/carousel.css"
import "./css/operations.css"
import "./css/activities.css"
import "./css/gallery.css"
import "./css/custom-teaser.css"
import "./css/cookiecontrol.css"
import "./css/support-button.css"
import "./css/testimonials.css"
import "./css/messages.css"
import "./css/faq.css"

import { onReady } from "./js/utils/dom"
import { initToggleNav } from "./js/components/nav"
import { initMap } from "./js/components/map"
import { initOperationsApp } from "./js/components/operations"
import { initActivitiesApp } from "./js/components/activities"
import { initSomeLinksInclude } from "./js/components/someLinks"
import {
  initNewsletterForm,
  initMessagesForm,
  initOperationsForm,
} from "./js/components/forms"
import { initGalleryImagesCarousel } from "./js/components/galleryImages"
import { initAnimations } from "./js/components/animations"
import { initInlineMap } from "./js/components/inlineMap"
import { initFishAnimation } from "./js/components/fishAnimationLoader"
import { initTestimonials } from "./js/components/testimonials"
import { initImageCropping } from "./js/utils/imageCroppingLoader"
import { initFAQ } from "./js/components/faq"

onReady(initToggleNav)
onReady(initMap)
onReady(initInlineMap)
onReady(initOperationsApp)
onReady(initActivitiesApp)
onReady(initSomeLinksInclude)
onReady(initGalleryImagesCarousel)
onReady(initAnimations)
onReady(initFishAnimation)
onReady(initNewsletterForm)
onReady(initMessagesForm)
onReady(initOperationsForm)
onReady(initTestimonials)
onReady(initImageCropping)
onReady(initFAQ)
