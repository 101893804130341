import { useState } from "react"
import { render } from "react-dom"
import { qs } from "../utils/dom"
import { FilterButton } from "./filterButton"
import { pillFilter } from "../utils/hooks"
import { ClipboardLink, FacebookLink } from "./someLinks"

const PAST_ACTIVITY_SLICE_SIZE = 12

export const initActivitiesApp = () => {
  const activitiesElement = qs("#activities-app")
  const data = qs("#activities-data")

  const pastActivitiesElement = qs("#past-activities-app")
  const pastData = qs("#past-activities-data")

  if (activitiesElement && data) {
    render(
      <ActivitiesApp {...JSON.parse(data.textContent)} />,
      activitiesElement,
    )
  }

  if (pastActivitiesElement && pastData) {
    render(
      <PastActivitiesApp {...JSON.parse(pastData.textContent)} />,
      pastActivitiesElement,
    )
  }
}

const ActivitiesApp = ({ cantonFilter, activities }) => {
  const [activeFilter, setActiveFilter] = useState([])

  const filteredActivities = activities.filter((o) => {
    return (
      activeFilter.length === 0 ||
      activeFilter.every((f) => o.cantons.includes(f))
    )
  })

  return (
    <div className="activities">
      <div className="flex-grid activities__filter-container">
        {cantonFilter.map((f) => {
          return (
            <FilterButton
              key={f[0]}
              active={activeFilter.includes(f[0])}
              name={f[1]}
              onClick={() => {
                pillFilter(activeFilter, setActiveFilter, f[0])
              }}
            />
          )
        })}
      </div>

      {filteredActivities.length === 0 ? (
        <div className="activities__activities-container">
          Keine Einträge gefunden.{" "}
          {activeFilter.length > 0 ? (
            <button
              className="button button--inline"
              onClick={() => {
                setActiveFilter([])
              }}
            >
              Filter zurücksetzen
            </button>
          ) : undefined}
        </div>
      ) : (
        <div className="grid activities__activities-container">
          {filteredActivities.map((a) => (
            <Activity key={a.id} {...a} />
          ))}
        </div>
      )}
    </div>
  )
}

const PastActivitiesApp = ({ activities }) => {
  const [slices, setSlices] = useState(1)

  return (
    <>
      {activities.length > 0 && <h1>Vergangene Aktivitäten</h1>}
      <div className="activities past-activities">
        <div className="grid activities__activities-container">
          {activities.slice(0, slices * PAST_ACTIVITY_SLICE_SIZE).map((a) => (
            <Activity key={a.id} {...a} />
          ))}
        </div>
        <div className="show-more">
          {activities.length > slices * PAST_ACTIVITY_SLICE_SIZE && (
            <button
              className="button button--more"
              onClick={() => {
                setSlices(slices + 1)
              }}
            >
              Mehr anzeigen
            </button>
          )}
        </div>
      </div>
    </>
  )
}

const Activity = (a) => {
  return (
    <div className={`card activity${a.is_featured ? " featured" : ""}`}>
      {a.image && (
        <div
          className="activity__image"
          style={{ backgroundImage: `url(${a.image})` }}
        />
      )}
      <div className="activity__info">
        <h3>{a.title}</h3>
        {(a.date || a.location_title) && (
          <div className="flex-grid column activity__info__details">
            {a.date && (
              <div className="flex">
                <svg className="icon">
                  <use xlinkHref="#icon-calendar" />
                </svg>
                <span>{a.date}</span>
              </div>
            )}
            {a.location_title && (
              <div className="flex">
                <svg className="icon">
                  <use xlinkHref="#icon-pin" />
                </svg>
                <span>{a.location_title}</span>
              </div>
            )}
          </div>
        )}
        <p>{a.text}</p>
      </div>
      <div className="activity__footer">
        <div className="flex-grid activity__some">
          <FacebookLink url={a.url} />
          <ClipboardLink url={a.url} />
        </div>
        <a className="button button--arrow activity__arrow" href={a.url}>
          <span>zur Aktivität</span>
          <svg className="icon">
            <use xlinkHref="#icon-arrow-forward" />
          </svg>
        </a>
      </div>
    </div>
  )
}
