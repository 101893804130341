import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)

export function initAnimation() {
  const start = 800
  const trigger1 = 1600
  const start2 = 3200
  const trigger2 = 3200
  const opacityRange = 500

  gsap.to("#fish1, #fish2, #fish3, #fish4", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start} center`,
      end: `+=${opacityRange}`,
    },
    opacity: 1,
  })

  gsap.to("#fish1", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start} center`,
    },
    motionPath: {
      path: "#path1",
      align: "#path1",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: -0.0,
      end: 1.15,
    },
  })
  gsap.to("#fish2", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start} center`,
    },
    motionPath: {
      path: "#path2",
      align: "#path2",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: -0.0,
      end: 1.15,
    },
  })
  gsap.to("#fish4", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start} center`,
    },
    motionPath: {
      path: "#path4",
      align: "#path4",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: -0.0,
      end: 1.15,
    },
  })
  gsap.to("#fish3", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start} center`,
    },
    motionPath: {
      path: "#path3",
      align: "#path3",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: -0.0,
      end: 1.15,
    },
  })

  gsap.to("#fish1, #fish2, #fish4", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${trigger1} center`,
      end: `+=${opacityRange}`,
    },
    opacity: 0,
  })
  gsap.to("#fish3", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${trigger1 + 200} center`,
      end: `+=${opacityRange}`,
    },
    opacity: 0,
  })

  gsap.to("#fish1", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start2} center`,
    },
    motionPath: {
      path: "#path1",
      align: "#path1",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: 0.4,
      end: 1.6,
    },
  })
  gsap.to("#fish2", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start2} center`,
    },
    motionPath: {
      path: "#path2",
      align: "#path2",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: 0.4,
      end: 1.6,
    },
  })
  gsap.to("#fish3", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start2} center`,
    },
    motionPath: {
      path: "#path3",
      align: "#path3",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: 0.4,
      end: 1.6,
    },
  })
  gsap.to("#fish4", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${start2} center`,
    },
    motionPath: {
      path: "#path4",
      align: "#path4",
      alignOrigin: [0.5, 0.5],
      autoRotate: 270,
      start: 0.4,
      end: 1.6,
    },
  })

  gsap.to("#fish1, #fish2, #fish4", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${trigger2} center`,
      end: `+=${opacityRange}`,
    },
    opacity: 1,
  })
  gsap.to("#fish3", {
    immediateRender: false,
    scrollTrigger: {
      scrub: 1,
      start: `top+=${trigger2 + 500} center`,
      end: `+=${opacityRange}`,
    },
    opacity: 1,
  })

  // gsap.to("#fish2", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //   },
  //   motionPath: {
  //     path: "#path2",
  //     align: "#path2",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: -0.07,
  //     end: 1.1,
  //   },
  // })

  // gsap.to("#fish3", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //   },
  //   motionPath: {
  //     path: "#path3",
  //     align: "#path3",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: -0.07,
  //     end: 1.1,
  //   },
  // })

  // gsap.to("#fish4", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //   },
  //   motionPath: {
  //     path: "#path4",
  //     align: "#path4",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: -0.07,
  //     end: 1.1,
  //   },
  // })

  // gsap.to("#fish5", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //   },
  //   motionPath: {
  //     path: "#path3",
  //     align: "#path3",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     end: 1,
  //   },
  // })

  // gsap.to("#fish1, #fish2, #fish4", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=800px",
  //     end: "top+=1200px",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish3", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=1000px",
  //     end: "top+=1500px",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish1, #fish2", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=2200px",
  //     end: "top+=2800px",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish3", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=3000px",
  //     end: "top+=3500px",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish4", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=3500px",
  //     end: "top+=4000px",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish5", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=4000px",
  //     end: "top+=4500px",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish6", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=57%",
  //     end: "top+=62%",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish7", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=60%",
  //     end: "top+=65%",
  //   },
  //   opacity: 1,
  // })

  // gsap.to("#fish1", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=45%",
  //   },
  //   motionPath: {
  //     path: "#path1",
  //     align: "#path1",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.6,
  //   },
  // })

  // gsap.to("#fish2", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=45%",
  //   },
  //   motionPath: {
  //     path: "#path2",
  //     align: "#path2",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.6,
  //   },
  // })

  // gsap.to("#fish3", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=50%",
  //   },
  //   motionPath: {
  //     path: "#path3",
  //     align: "#path3",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.6,
  //   },
  // })

  // gsap.to("#fish4", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=53%",
  //   },
  //   motionPath: {
  //     path: "#path4",
  //     align: "#path4",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.65,
  //   },
  // })

  // gsap.to("#fish5", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=55%",
  //   },
  //   motionPath: {
  //     path: "#path1",
  //     align: "#path1",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.65,
  //   },
  // })

  // gsap.to("#fish6", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=57%",
  //   },
  //   motionPath: {
  //     path: "#path2",
  //     align: "#path2",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.65,
  //   },
  // })

  // gsap.to("#fish7", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: 1,
  //     start: "top+=60%",
  //   },
  //   motionPath: {
  //     path: "#path4",
  //     align: "#path4",
  //     alignOrigin: [0.5, 0.5],
  //     autoRotate: 270,
  //     start: 0.65,
  //   },
  // })

  // gsap.to("#fish1, #fish2", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=70%",
  //     end: "top+=75%",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish3", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=71%",
  //     end: "top+=76%",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish4", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=72%",
  //     end: "top+=77%",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish5", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=72%",
  //     end: "top+=77%",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish6", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=73%",
  //     end: "top+=78%",
  //   },
  //   opacity: 0,
  // })

  // gsap.to("#fish7", {
  //   immediateRender: false,

  //   scrollTrigger: {
  //     scrub: true,
  //     start: "top+=73%",
  //     end: "top+=78%",
  //   },
  //   opacity: 0,
  // })
}
