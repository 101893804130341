import { Children } from "react"

export const Masonry = ({
  styleClass,
  columnStyleClass,
  columns,
  children,
}) => {
  const currentColumns = Number(columns)

  const columnItems = () => {
    const itemsInColumns = new Array(currentColumns)
    const items = Children.toArray(children)

    for (let i = 0; i < items.length; i++) {
      const columnIndex = i % currentColumns

      if (!itemsInColumns[columnIndex]) {
        itemsInColumns[columnIndex] = []
      }

      itemsInColumns[columnIndex].push(items[i])
    }

    return itemsInColumns
  }

  const gridColumns = () => {
    const columnAttrs = {}
    const calculatedColumnItems = columnItems()
    const columnWidth = `${100 / calculatedColumnItems.length}%`

    const columnAttributes = {
      ...columnAttrs,
      style: {
        width: columnWidth,
      },
      className: columnStyleClass,
    }

    return calculatedColumnItems.map((items, i) => {
      return (
        <div {...columnAttributes} key={i}>
          {items}
        </div>
      )
    })
  }

  return <div className={styleClass}>{gridColumns()}</div>
}
